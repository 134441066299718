define("discourse/plugins/discourse-user-card-badges/discourse/initializers/initialize-user-card-badges", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function modifyUserCardContents(api) {
    var _dec, _obj;
    api.modifyClass("component:user-card-contents", (_dec = (0, _decorators.default)("user.card_badge.image"), (_obj = {
      pluginId: "discourse-user-card-badges",
      classNameBindings: ["hasCardBadgeImage"],
      hasCardBadgeImage(image) {
        return image && image.indexOf("fa-") !== 0;
      }
    }, (_applyDecoratedDescriptor(_obj, "hasCardBadgeImage", [_dec], Object.getOwnPropertyDescriptor(_obj, "hasCardBadgeImage"), _obj)), _obj)));
  }
  var _default = _exports.default = {
    name: "user-card-badges",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.19", api => {
        modifyUserCardContents(api);
      });
    }
  };
});