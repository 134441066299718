define("discourse/plugins/discourse-user-card-badges/discourse/controllers/preferences-card-badge", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/models/badge", "discourse-common/utils/decorators", "I18n"], function (_exports, _controller, _object, _service, _ajax, _badge, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("model"), _dec2 = (0, _decorators.default)("filteredList"), _dec3 = (0, _decorators.default)("saving"), _dec4 = (0, _decorators.default)("selectedUserBadgeId"), (_obj = {
    dialog: (0, _service.inject)(),
    saving: false,
    saved: false,
    filteredList(model) {
      return model.filterBy("badge.image");
    },
    selectableUserBadges(filteredList) {
      return [_object.default.create({
        badge: _badge.default.create({
          name: _I18n.default.t("badges.none")
        })
      }), ...filteredList.uniqBy("badge.name")];
    },
    savingStatus(saving) {
      return saving ? "saving" : "save";
    },
    selectedUserBadge(selectedUserBadgeId) {
      return this.selectableUserBadges.findBy("id", parseInt(selectedUserBadgeId, 10));
    },
    actions: {
      save() {
        this.setProperties({
          saved: false,
          saving: true
        });
        (0, _ajax.ajax)(`${this.user.path}/preferences/card-badge`, {
          type: "PUT",
          data: {
            user_badge_id: this.selectedUserBadgeId
          }
        }).then(() => {
          this.setProperties({
            saved: true,
            saving: false,
            "user.card_image_badge": this.get("selectedUserBadge.badge.image")
          });
        }).catch(() => {
          this.set("saving", false);
          this.dialog.alert(_I18n.default.t("generic_error"));
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "filteredList", [_dec], Object.getOwnPropertyDescriptor(_obj, "filteredList"), _obj), _applyDecoratedDescriptor(_obj, "selectableUserBadges", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectableUserBadges"), _obj), _applyDecoratedDescriptor(_obj, "savingStatus", [_dec3], Object.getOwnPropertyDescriptor(_obj, "savingStatus"), _obj), _applyDecoratedDescriptor(_obj, "selectedUserBadge", [_dec4], Object.getOwnPropertyDescriptor(_obj, "selectedUserBadge"), _obj)), _obj)));
});